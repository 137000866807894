<template>
    <div class="ui-cell-item">
        <div class="ui-cell-icon">
            <slot name="icon"></slot>
        </div>
        <div class="ui-cell-main">
            <div class="ui-cell-title"><slot>{{ title }}</slot></div>
            <div class="ui-cell-label"><slot name="label">{{ label }}</slot></div>
        </div>
        <div class="ui-cell-footer">
            <span class="ui-cell-extra"><slot name="extra">{{ extra }}</slot></span>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            title: {
                type: String,
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
            extra: {
                type: String,
                default: ''
            },
        }
    };
</script>
