import {isObj, isString, isArray} from "./assist";

const newLine = '\r\n';
const appendLine = (content, row, {separator, quoted}) => {
    const line = row.map(data => {
        if (!quoted) {
            return data;
        }
        // quote data
        data = typeof data === 'string' ? data.replace(/"/g, '"') : data;
        return `"${data}"`;
    });
    content.push(line.join(separator));
};

const defaults = {
    separator: ',',
    quoted: true,
};


export const txtRender = (type, props = null, children = null) => {
    let child = props;
    if (isObj(props) && !Array.isArray(props)) {
        child = children;
    }
    if (child != null) {
        if (isArray(child)) {
            return child.join(', ');
        } else {
            return child;
        }
    }

    return '';
}


export default function csv(columns, datas, options, noHeader = false) {
    options = Object.assign({}, defaults, options);
    let columnOrder = [];
    const content = [];
    const column = [];

    if (columns) {
        columns.forEach(v => {
            if (typeof v === 'string') {
                columnOrder.push(v);
            }

            if (!noHeader) {
                if (v.key) {
                    column.push(typeof v.title !== 'undefined' ? v.title : v.key);
                }
            }

            if (v.key) {
                columnOrder.push(v.key);
            }
        });

        if (column.length > 0) {
            appendLine(content, column, options);
        }
    } else {
        columnOrder = [];
        datas.forEach(v => {
            if (!Array.isArray(v)) {
                columnOrder = columnOrder.concat(Object.keys(v));
            }
        });
        if (columnOrder.length > 0) {
            columnOrder = columnOrder.filter((value, index, self) => {
                return self.indexOf(value) === index
            });
            if (!noHeader) {
                appendLine(content, columnOrder, options);
            }
        }
    }

    if (Array.isArray(datas)) {
        datas.forEach(row => {
            if (!Array.isArray(row)) {
                row = columnOrder.map((k) => {
                    let txt = null;
                    columns.forEach((col) => {
                        if (col.key && (col.key === k)) {
                            // txt: (row) => {
                            //     return row.order_no + 'MM';
                            // }
                            if (col.txt) {
                                txt = col.txt(row);
                            } else if (col.render) {
                                txt = col.render(txtRender, {row})
                            }
                        }
                    })
                    if (txt !== null) {
                        return txt;
                    }

                    if (typeof row[k] !== 'undefined') {
                        return row[k];
                    }
                    return '';
                });
            }
            appendLine(content, row, options);
        });
    }
    return content.join(newLine);
}
