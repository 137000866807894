import Vue from 'vue';

const isServer = Vue.prototype.$isServer;

export function oneOf(value, validList) {
    for (let i = 0; i < validList.length; i++) {
        if (value === validList[i]) {
            return true;
        }
    }
    return false;
}

export function camelcaseToHyphen(str) {
    return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

// For Modal scrollBar hidden
let cached;

export function getScrollBarSize(fresh) {
    if (isServer) {
        return 0;
    }
    if (fresh || cached === undefined) {
        const inner = document.createElement('div');
        inner.style.width = '100%';
        inner.style.height = '200px';

        const outer = document.createElement('div');
        const outerStyle = outer.style;

        outerStyle.position = 'absolute';
        outerStyle.top = 0;
        outerStyle.left = 0;
        outerStyle.pointerEvents = 'none';
        outerStyle.visibility = 'hidden';
        outerStyle.width = '200px';
        outerStyle.height = '150px';
        outerStyle.overflow = 'hidden';

        outer.appendChild(inner);

        document.body.appendChild(outer);

        const widthContained = inner.offsetWidth;
        outer.style.overflow = 'scroll';
        let widthScroll = inner.offsetWidth;

        if (widthContained === widthScroll) {
            widthScroll = outer.clientWidth;
        }

        document.body.removeChild(outer);

        cached = widthContained - widthScroll;
    }
    return cached;
}

// watch DOM change
export const MutationObserver = isServer ? false : window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver || false;

const SPECIAL_CHARS_REGEXP = /([\:\-\_]+(.))/g;
const MOZ_HACK_REGEXP = /^moz([A-Z])/;

export function camelCase(name) {
    return name.replace(SPECIAL_CHARS_REGEXP, function (_, separator, letter, offset) {
        return offset ? letter.toUpperCase() : letter;
    }).replace(MOZ_HACK_REGEXP, 'Moz$1');
}

// getStyle
export function getStyle(element, styleName) {
    if (!element || !styleName) {
        return null;
    }
    styleName = camelCase(styleName);
    if (styleName === 'float') {
        styleName = 'cssFloat';
    }
    try {
        const computed = document.defaultView.getComputedStyle(element, '');
        return element.style[styleName] || computed ? computed[styleName] : null;
    } catch (e) {
        return element.style[styleName];
    }
}

// firstUpperCase
export function firstUpperCase(str) {
    return str.toString()[0].toUpperCase() + str.toString().slice(1);
}


// Warn
export function warnProp(component, prop, correctType, wrongType) {
    correctType = firstUpperCase(correctType);
    wrongType = firstUpperCase(wrongType);
    console.error(`[UltraUI warn]: Invalid prop: type check failed for prop ${prop}. Expected ${correctType}, got ${wrongType}. (found in component: ${component})`);    // eslint-disable-line
}

export function typeOf(obj) {
    const toString = Object.prototype.toString;
    const map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object'
    };
    return map[toString.call(obj)];
}

// deepCopy
export function deepCopy(data) {
    const t = typeOf(data);
    let o;

    if (t === 'array') {
        o = [];
    } else if (t === 'object') {
        o = {};
    } else {
        return data;
    }

    if (t === 'array') {
        for (let i = 0; i < data.length; i++) {
            o.push(deepCopy(data[i]));
        }
    } else if (t === 'object') {
        for (let i in data) {
            o[i] = deepCopy(data[i]);
        }
    }
    return o;
}

// scrollTop animation
export function scrollTop(el, from = 0, to, duration = 500, endCallback) {
    if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = (
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            function (callback) {
                return window.setTimeout(callback, 1000 / 60);
            }
        );
    }
    const difference = Math.abs(from - to);
    const step = Math.ceil(difference / duration * 50);

    function scroll(start, end, step) {
        if (start === end) {
            endCallback && endCallback();
            return;
        }

        let d = (start + step > end) ? end : start + step;
        if (start > end) {
            d = (start - step < end) ? end : start - step;
        }

        if (el === window) {
            window.scrollTo(d, d);
        } else {
            el.scrollTop = d;
        }
        window.requestAnimationFrame(() => scroll(d, end, step));
    }

    scroll(from, to, step);
}

// Find components upward
export function findComponentUpward(context, componentName, componentNames) {
    if (typeof componentName === 'string') {
        componentNames = [componentName];
    } else {
        componentNames = componentName;
    }

    let parent = context.$parent;
    let name = parent.$options.name;
    while (parent && (!name || componentNames.indexOf(name) < 0)) {
        parent = parent.$parent;
        if (parent) {
            name = parent.$options.name;
        }
    }
    return parent;
}


// Find component downward
export function findComponentDownward(context, componentName) {
    const childrens = context.$children;
    let children = null;

    if (childrens.length) {
        for (const child of childrens) {
            const name = child.$options.name;
            if (name === componentName) {
                children = child;
                break;
            } else {
                children = findComponentDownward(child, componentName);
                if (children) {
                    break;
                }
            }
        }
    }
    return children;
}

// Find components downward
export function findComponentsDownward(context, componentName, ignoreComponentNames = []) {
    if (!Array.isArray(ignoreComponentNames)) {
        ignoreComponentNames = [ignoreComponentNames];
    }
    return context.$children.reduce((components, child) => {
        if (child.$options.name === componentName) {
            components.push(child);
        }
        if (ignoreComponentNames.indexOf(child.$options.name) < 0) {
            const foundChilds = findComponentsDownward(child, componentName);
            return components.concat(foundChilds);
        } else {
            return components;
        }
    }, []);
}

// Find components upward
export function findComponentsUpward(context, componentName) {
    let parents = [];
    const parent = context.$parent;
    if (parent) {
        if (parent.$options.name === componentName) {
            parents.push(parent);
        }
        return parents.concat(findComponentsUpward(parent, componentName));
    } else {
        return [];
    }
}

// Find brothers components
export function findBrothersComponents(context, componentName, exceptMe = true) {
    let res = context.$parent.$children.filter(item => {
        return item.$options.name === componentName;
    });
    let index = res.findIndex(item => item._uid === context._uid);
    if (exceptMe) {
        res.splice(index, 1);
    }
    return res;
}


export function trim(string) {
    return (string || '').replace(/^[\s\uFEFF]+|[\s\uFEFF]+$/g, '');
}


export function hasClass(el, cls) {
    if (!el || !cls) {
        return false;
    }
    if (cls.indexOf(' ') !== -1) {
        throw new Error('className should not contain space.');
    }
    if (el.classList) {
        return el.classList.contains(cls);
    } else {
        return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1;
    }
}


export function addClass(el, cls) {
    if (!el) {
        return;
    }
    let curClass = el.className;
    const classes = (cls || '').split(' ');

    for (let i = 0, j = classes.length; i < j; i++) {
        const clsName = classes[i];
        if (!clsName) {
            continue;
        }

        if (el.classList) {
            el.classList.add(clsName);
        } else {
            if (!hasClass(el, clsName)) {
                curClass += ' ' + clsName;
            }
        }
    }
    if (!el.classList) {
        el.className = curClass;
    }
}


export function removeClass(el, cls) {
    if (!el || !cls) {
        return;
    }
    const classes = cls.split(' ');
    let curClass = ' ' + el.className + ' ';

    for (let i = 0, j = classes.length; i < j; i++) {
        const clsName = classes[i];
        if (!clsName) {
            continue;
        }

        if (el.classList) {
            el.classList.remove(clsName);
        } else {
            if (hasClass(el, clsName)) {
                curClass = curClass.replace(' ' + clsName + ' ', ' ');
            }
        }
    }
    if (!el.classList) {
        el.className = trim(curClass);
    }
}


export const dimensionMap = {
    xs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
};


export function setMatchMedia() {
    if (typeof window !== 'undefined') {
        const matchMediaPolyfill = mediaQuery => {
            return {
                media: mediaQuery,
                matches: false,
                on() {
                },
                off() {
                },
            };
        };
        window.matchMedia = window.matchMedia || matchMediaPolyfill;
    }
}


export const sharpMatcherRegx = /#([^#]+)$/;


export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) {
        return '0 Byte';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};


export const duration = (date, to = null) => {
    let nMilliseconds = 0;
    if (to) {
        nMilliseconds = 0;//moment(to).diff(date);
    } else {
        nMilliseconds = 0;//moment().diff(date);
    }
    let sFormat = '';
    if (nMilliseconds > (1000 * 3600 * 24 * 7)) {//大于一周
        sFormat = "DDD[" + Vue.i18n.t('Days') + "]";
    } else if (nMilliseconds > (1000 * 3600 * 24 * 3)) {//大于3天
        sFormat = "DDD[" + Vue.i18n.t('Days') + "] H[" + Vue.i18n.t('Hours') + "]";
    } else if (nMilliseconds > (1000 * 3600 * 24)) {//大于一天
        sFormat = "DDD[" + Vue.i18n.t('Days') + "] HH:mm:ss";
    } else {
        sFormat = "HH:mm:ss";
    }

    return '';
    //return moment.utc(nMilliseconds).format(sFormat);
};


export const _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
} : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};


export const isObj = (value) => {
    const type = typeof value;
    return value !== null && (type === 'object' || type === 'function');
};


export const objEqual = (obj1, obj2) => {
    let props1 = Object.getOwnPropertyNames(obj1);
    let props2 = Object.getOwnPropertyNames(obj2);
    if (props1.length !== props2.length) {
        return false;
    }
    for (let i = 0; i < props1.length; i++) {
        let val1 = obj1[props1[i]];
        let val2 = obj2[props1[i]];
        let isObjects = isObj(val1) && isObj(val2);
        if (isObjects && !isEqual(val1, val2) || !isObjects && val1 !== val2) {
            return false;
        }
    }
    return true;
}


export const arrayEqual = (a, b) => {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}


export const empty = (mixedVar) => {
    //   example 1: empty(null)
    //   returns 1: true
    //   example 2: empty(undefined)
    //   returns 2: true
    //   example 3: empty([])
    //   returns 3: true
    //   example 4: empty({})
    //   returns 4: true
    //   example 5: empty({'aFunc' : function () { alert('humpty'); } })
    //   returns 5: false
    let undef;
    let key;
    let i;
    let len;
    let emptyValues = [undef, null, false, 0, '', '0'];

    for (i = 0, len = emptyValues.length; i < len; i++) {
        if (mixedVar === emptyValues[i]) {
            return true;
        }
    }

    if ((typeof mixedVar === 'undefined' ? 'undefined' : _typeof(mixedVar)) === 'object') {
        for (key in mixedVar) {
            if (mixedVar.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    return false;
};


export const isArray = (mixedVar) => {
    return Array.isArray(mixedVar) || (Object.prototype.toString.call(mixedVar).slice(8, -1) === 'Array');
};


export const isString = (mixedVar) => {
    return (Object.prototype.toString.call(mixedVar) === '[object String]');
};


export const getPathSegments = (path) => {
    const pathArray = path.split('.');
    const parts = [];

    for (let i = 0; i < pathArray.length; i++) {
        let p = pathArray[i];

        while (p[p.length - 1] === '\\' && pathArray[i + 1] !== undefined) {
            p = p.slice(0, -1) + '.';
            p += pathArray[++i];
        }

        parts.push(p);
    }

    return parts;
};

export const fill = (value, object, path) => {
    if (!isObj(object) || typeof path !== 'string') {
        return object;
    }
    const root = object;
    const pathArray = getPathSegments(path);
    for (let i = 0; i < pathArray.length; i++) {
        const p = pathArray[i];
        //要是不是对象，就设置一个空对象
        if (!isObj(object[p])) {
            this.$set(object, p, {});
        }
        //最后一个直接设置value
        if (i === pathArray.length - 1) {
            this.$set(object, p, value);
        }
        object = object[p];
    }

    return root;
};


export const set = (object, path, value) => {
    if (!isObj(object) || typeof path !== 'string') {
        return object;
    }

    const root = object;
    const pathArray = getPathSegments(path);

    for (let i = 0; i < pathArray.length; i++) {
        const p = pathArray[i];

        if (!isObj(object[p])) {
            object[p] = {};
        }

        if (i === pathArray.length - 1) {
            object[p] = value;
        }

        object = object[p];
    }

    return root;
};


export const del = (object, path) => {
    if (!isObj(object) || typeof path !== 'string') {
        return;
    }

    const pathArray = getPathSegments(path);

    for (let i = 0; i < pathArray.length; i++) {
        const p = pathArray[i];

        if (i === pathArray.length - 1) {
            delete object[p];
            return;
        }

        object = object[p];

        if (!isObj(object)) {
            return;
        }
    }
};


export const has = (object, path) => {
    if (!isObj(object) || typeof path !== 'string') {
        return false;
    }

    const pathArray = getPathSegments(path);

    for (let i = 0; i < pathArray.length; i++) {
        if (isObj(object)) {
            if (!(pathArray[i] in object)) {
                return false;
            }

            object = object[pathArray[i]];
        } else {
            return false;
        }
    }

    return true;
};


export const get = (object, path, value) => {
    if (!isObj(object) || typeof path !== 'string') {
        return value === undefined ? object : value;
    }
    const pathArray = getPathSegments(path);
    for (let i = 0; i < pathArray.length; i++) {
        if (!Object.prototype.propertyIsEnumerable.call(object, pathArray[i])) {
            return value;
        }
        object = object[pathArray[i]];
        if (object === undefined || object === null) {
            if (i !== pathArray.length - 1) {
                return value;
            }
            break;
        }
    }

    return object;
};


export const basename = (path) => {
    return path.split(/[\\/]/).pop();
}


export const download = (sUrl, sSaveAs = null) => {
    fetch(sUrl, {
        method: 'GET',
        mode: 'cors',
        responseType: 'blob',
    }).then((response) => {
        if (response.ok) {
            return response.blob();
        }
        throw new Error('Download error for file ' + sUrl + ': ' + response.statusText);
    }).then((blob) => {
        let fileURL = window.URL.createObjectURL(blob);
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        if (empty(sSaveAs)) {
            sSaveAs = basename(sUrl);
        }
        fileLink.setAttribute('download', sSaveAs);
        document.body.appendChild(fileLink);
        fileLink.click();
    });
};


export const notify = (sTitle, options = {}) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {// Let's check whether notification permissions have already been granted
        // If it's okay let's create a notification
        var notification = new Notification(sTitle, options);
    } else if (Notification.permission !== "denied") { // Otherwise, we need to ask the user for permission
        Notification.requestPermission().then(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                var notification = new Notification(sTitle, options);
            }
        });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
}


export const persistWriter = (key, storeAsJson = true) => {
    return (data) => {
        if (data === null) {
            return localStorage.removeItem(key);
        }

        return localStorage.setItem(key, storeAsJson ? JSON.stringify(data) : data);
    };
};


export const persistReader = (key, defaultData = null, decodeAsJson = true) => {
    return () => {
        const data = localStorage.getItem(key);
        if (data) {
            try {
                if (decodeAsJson) {
                    return JSON.parse(data);
                }
            } catch (e) {
                console.log(`Parse json data failed of local storage key ${key}`);
                return defaultData;
            }
        } else {
            return defaultData;
        }
    };
};


export const getBrowserLanguage = () => {
    let defaultLang = navigator.language;
    switch (defaultLang) {
        case 'zh-CN':
            return 'zh_CN';
        case 'zh-TW':
        case 'zh-HK':
        case 'zh-SG':
            return 'zh_TW';
        default:
            return 'en_US';
    }
};


export const unifyTreeChecked = (arrCheckedAndIndeterminateNodes, bOnlyParentIfAllChildrenChecked = false) => {
    if (!arrCheckedAndIndeterminateNodes) {
        return [];
    }

    const rootNode = arrCheckedAndIndeterminateNodes.find((eachNode) => {
        return eachNode.root;
    });

    if (!rootNode) {
        return [];
    }

    //针对文件：
    //从root开始，向下查找，只需要记录勾选状态，且未展开的节点，某层次遇到勾选状态，则忽略更低层次的子节点
    //针对数据库：
    //从root开始，向下直到找到target: true的节点，忽略该target更低层次的子节点

    let arrPickedNode = [];

    let pick = (eachNode, parentNode) => {
        if (bOnlyParentIfAllChildrenChecked) {
            if (eachNode.checked && (eachNode.expand === false)) {
                let theNodePlain = Object.assign({}, eachNode.data);
                arrPickedNode.push(theNodePlain);
            } else {
                if (eachNode.children) {
                    eachNode.children.map((eachChild) => {
                        pick(eachChild, eachNode);
                    });
                }
            }
        } else {
            if (eachNode.checked && eachNode.target) {
                let theNodePlain = Object.assign({}, eachNode.data);
                if (!parentNode.root) {
                    let arrParent = Object.assign({}, parentNode.data);
                    delete arrParent.arrChildren;
                    eachNode.parent = arrParent;

                    theNodePlain.arrParent = arrParent;
                }

                arrPickedNode.push(theNodePlain);
            } else {
                if (eachNode.children) {
                    eachNode.children.map((eachChild) => {
                        pick(eachChild, eachNode);
                    });
                }
            }
        }
    };

    pick(rootNode, null);

    return arrPickedNode;
};


export const debounce = (that, fn, delay) => {
    let timer = null;
    return function () {
        let args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fn.apply(that, args);
        }, delay);
    };
};


export const permit = (arrNeedAnyPermissions, arrProvidedPermissions = null) => {
    if (arrProvidedPermissions === null || arrProvidedPermissions === '') {
        if (!this || !this.$store) {
            console.error('this.$store is not initialized, permit access default');
            return true;
        }
        arrProvidedPermissions = this.$store.getters['permissions'];
    }

    //Flatten to 1d array
    let arrUserProvidedPermissions = [].concat.apply([], arrProvidedPermissions);

    let bFindMatch = false;
    if (empty(arrNeedAnyPermissions)) {
        return true;
    }
    if (!isArray(arrNeedAnyPermissions)) {
        arrNeedAnyPermissions = [arrNeedAnyPermissions];
    }
    if (arrNeedAnyPermissions.length === 0) {
        return true;
    }
    arrNeedAnyPermissions.forEach((sPermission) => {
        arrUserProvidedPermissions.forEach((sOneHave) => {
            if (sOneHave === "/") { // "/" is a special permission, it means all permissions
                bFindMatch = true;
            }
            if (sPermission === sOneHave) { // Same
                bFindMatch = true;
            }
            if (sOneHave.startsWith(sPermission + '/')) { // EG: /User/ /User/delete
                bFindMatch = true;
            }
        })
    });

    return bFindMatch;
};


export const RMBUppercase = (digital) => {
    const fraction = ['角', '分'];
    const digit = [
        '零', '壹', '贰', '叁', '肆',
        '伍', '陆', '柒', '捌', '玖'
    ];
    const unit = [
        ['元', '万', '亿'],
        ['', '拾', '佰', '仟']
    ];
    const IsNum = Number(digital);
    if (!isNaN(IsNum)) {
        let head = digital < 0 ? '负' : '';
        digital = Math.abs(digital);
        let s = '';
        for (let i = 0; i < fraction.length; i++) {
            s += (digit[Math.floor(digital * 100 / 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
        }
        s = s || '整';
        digital = Math.floor(digital);
        for (let i = 0; i < unit[0].length && digital > 0; i++) {
            let p = '';
            for (let j = 0; j < unit[1].length && digital > 0; j++) {
                p = digit[digital % 10] + unit[1][j] + p;
                digital = Math.floor(digital / 10);
            }
            s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
        }
        return head + s.replace(/(零.)*零元/, '元')
            .replace(/(零.)+/g, '零')
            .replace(/^整$/, '零元整');
    } else {
        return "";
    }

};


//EG: statesMap(0, ['进行中', '已完成', '已取消'])
//EG: statesMap('STARTED', {STARTED:'进行中', FINISHED:'已完成', CANCELED: '已取消'], '未知状态')
export const statesMap = (nState, arrStates = [], sDefault = '') => {
    if (empty(arrStates)) {
        return sDefault;
    }
    if (isArray(arrStates)) {
        if (nState < arrStates.length) {
            return arrStates[nState];
        } else {
            return sDefault;
        }
    } else if (isObj(arrState)) {
        if (nState in arrStates) {
            return arrStates[nState];
        } else {
            return sDefault;
        }
    } else {
        return sDefault;
    }
};


const assist = {
    addClass,
    basename,
    camelCase,
    camelcaseToHyphen,
    deepCopy,
    empty,
    fill,
    get,
    has,
    set,
    debounce,
    del,
    download,
    duration,
    firstUpperCase,
    formatBytes,
    getBrowserLanguage,
    getStyle,
    hasClass,
    isObj,
    isArray,
    isString,
    objEqual,
    arrayEqual,
    notify,
    persistReader,
    persistWriter,
    removeClass,
    typeOf,
    unifyTreeChecked,
    permit,
    RMBUppercase,
    statesMap,
};


export default assist;
