export default {
    data() {
        return {
            prefixCls: 'ui-color-picker',
            inputPrefixCls: 'ui-input',
            iconPrefixCls: 'ui-icon',
            transferPrefixCls: 'ui-transfer',
        };
    },
};
