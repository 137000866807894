<template>
    <div :class="classes">
        <slot></slot>
    </div>
</template>
<script>
import {arrayEqual, findComponentsDownward, isArray, oneOf} from '../../utils/assist';
    import Emitter                                          from '../../mixins/emitter';

    const prefixCls = 'ui-checkbox-group';

    export default {
        name: 'CheckboxGroup',
        mixins: [ Emitter ],
        props: {
            value: {
                type: Array,
                default () {
                    return [];
                }
            },
            size: {
                validator (value) {
                    return oneOf(value, ['small', 'large', 'default']);
                },
                default () {
                    return !this.$UltraUI || this.$UltraUI.size === '' ? 'default' : this.$UltraUI.size;
                }
            }
        },
        data () {
            return {
                currentValue: this.value,
                children: []
            };
        },
        computed: {
            classes () {
                return [
                    `${prefixCls}`,
                    {
                        [`ui-checkbox-${this.size}`]: !!this.size
                    }
                ];
            }
        },
        mounted () {
            this.updateModel(true);
        },
        methods: {
            updateModel (update) {
                this.children = findComponentsDownward(this, 'Checkbox', 'CheckboxGroup');
                if (this.children) {
                    const { value } = this;
                    this.children.forEach(child => {
                        child.model = value;

                        if (update) {
                            if (isArray(child.label) && (child.label.length > 0)) { // not include empty array
                                let isExists = false;
                                value.forEach(val => {
                                    if (arrayEqual(child.label, val)) {
                                        isExists = true;
                                    }
                                });
                                child.currentValue = isExists;
                            } else {
                                child.currentValue = value.indexOf(child.label) >= 0;
                            }

                            child.group = true;
                        }
                    });
                }
            },
            change (data) {
                this.currentValue = data;
                this.$emit('input', data);
                this.$emit('on-change', data);
                this.dispatch('FormItem', 'on-form-change', data);
            }
        },
        watch: {
            value () {
                this.updateModel(true);
            }
        }
    };
</script>
