export default {
    data() {
        return {
            nWindowResizeCounter: 0,
            bMounted: false,
        };
    },
    created() {
        window.addEventListener("resize", this.onWindowResize);
    },
    mounted() {
        this.bMounted = true;
    },
    destroyed() {
        window.removeEventListener("resize", this.onWindowResize);
    },
    methods: {
        onWindowResize() {
            this.nWindowResizeCounter++;
        },
        height(sSelector, nStripHeight = 0, bReturnString = false, bSelectorIsChildDom = true) {
            //Keep track of the window resize counter
            let trigger = this.nWindowResizeCounter;
            let nHeight = 0;
            if (this.bMounted) {
                let dom = null;
                if (bSelectorIsChildDom){
                    dom = this.$el.querySelector(sSelector);
                } else {
                    dom = document.querySelector(sSelector);
                }
                if (dom) {
                    nHeight = dom.clientHeight;
                }
            }
            if (nHeight > nStripHeight) {
                nHeight -= nStripHeight;
            }
            if (bReturnString) {
                return `height: ${nHeight}px;`;
            }
            return nHeight;
        },
    },
};
