import WindowResize from "./WindowResize";

export default {
    mixins: [WindowResize],
    data() {
        return {
            bLoading: false,
            arrColumns: [],

            nTotal: 0,
            arrPager: {
                nPageSize: 20,
                nCurrentPage: 1,
            },
            arrFilters: {},

            bAutoLoadList: true,
            arrList: [],
            currentRow: {},
            arrChecked: [],

            arrModals: {},
            arrLoadings: {},
            bShowDetailWindow: true,
            nDetailWindowHeight: 160,

            bEditing: false,
            arrSetForm: {},
            sCurrentTab: '',
        };
    },
    computed: {
        bRowClicked() {
            return this.currentRow && Object.getOwnPropertyNames(this.currentRow).length > 1;//Except __ob__
        },
    },
    mounted() {
        if (this.bAutoLoadList) {
            this.refreshList();
        }
    },
    methods: {
        refreshList(bSetLoadingFlag = true, bCleanCurrentRow = true, bResetCurrentPage = false) {
            let _this = this;
            if (bSetLoadingFlag) {
                _this.bLoading = true;
            }
            if (bResetCurrentPage) {
                _this.arrPager.nCurrentPage = 1;
            }
            _this.apiGetList(_this.arrFilters, _this.arrPager).then((response) => {
                if (response.data) {
                    if (response.data.hasOwnProperty('nTotal')) {
                        _this.nTotal = response.data.nTotal;
                        _this.arrList = response.data.arrList;
                    } else {
                        _this.arrList = response.data;
                    }
                } else {
                    console.log("Invalid response format while getting list.");
                    _this.arrList = [];
                }

            }).finally(() => {
                if (bSetLoadingFlag) {
                    _this.bLoading = false;
                }
                _this.arrChecked = [];
                if (bCleanCurrentRow) {
                    _this.currentRow = {};
                }
            });
        },
        apiGetList(arrFilters, arrPager) {
            return Promise.resolve({data: {nTotal: 0, arrList: []}});
        },
        exports(sType = 'PAGE', sFileName = 'exported', sFormat = 'CSV') {
            if (sFormat === 'CSV') {
                if (sType === 'ALL') {
                    this.exportsAll(sFileName, sFormat);
                } else if (sType === 'SELECTED') {
                    this.exportsSelected(sFileName, sFormat);
                } else {
                    this.$refs.table.exportCsv({
                        filename: sFileName,
                    });
                }
            }
        },
        exportsAll(sFileName = 'exported', sFormat = 'CSV') {
            let _this = this;
            let arrData = [];
            this.apiGetList(this.arrFilters, []).then((response) => {
                if (response.data) {
                    if (response.data.hasOwnProperty('nTotal')) {
                        arrData = response.data.arrList;
                    } else {
                        arrData = response.data;
                    }
                } else {
                    arrData = [];
                }
            }).finally(() => {
                if (sFormat === 'CSV') {
                    this.$refs.table.exportCsv({
                        filename: sFileName,
                        columns: _this.arrColumns,
                        data: arrData,
                    });
                }
            });
        },
        exportsSelected(sFileName = 'exported', sFormat = 'CSV') {
            if (sFormat === 'CSV') {
                this.$refs.table.exportCsv({
                    filename: sFileName,
                    columns: this.arrColumns,
                    data: this.arrChecked,
                });
            }
        },
    },
}
