import assist  from "../utils/assist";
import emitter from "./emitter";

export default {
    mixins: [emitter],
    computed: {
        routeClass() {
            return this.$route.path.toLocaleLowerCase().replace(/\//g, '-').substr(1);
        },
        VARS() {
            return window.VARS;//==self
        },
    },
    methods: {
        fill(value, object, path) {
            assist.fill(value, object, path);
        },
        get(object, path, value) {
            assist.get(object, path, value);
        },
        empty(mixedVar) {
            return assist.empty(mixedVar);
        },
        isArray(mixedVar) {
            return assist.isArray(mixedVar);
        },
    }
}

