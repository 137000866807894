import {empty, getBrowserLanguage, persistReader, persistWriter} from "../utils/assist";


export const SET_LOCALE = 'SET_LOCALE';
export const SET_SYSTEM = 'SET_SYSTEM';
export const SET_MENUS = 'SET_MENUS';
export const SET_LOGIN = 'SET_LOGIN';
export const SET_USER = 'SET_USER';
export const SET_HELP = 'SET_HELP';
export const SET_FLAGS = 'SET_FLAGS';

export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_ONE_FLAG = 'SET_ONE_FLAG';


const state = {
    locale: 'zh_CN',
    system: {
        version: '',
        vendor: '',
        config: {},
    },
    menus: [],
    login: {
        user: '',
        password: '',
        remember: false,
        auto: false,
    },
    user: {
        id: 0,
        name: '',
        avatar: '',
        role: '',
        permissions: [],
        token: '',
    },
    help: null,
    flags: {},
};


const getters = {
    locale: state => state.locale,
    system: state => state.system,
    menus: state => state.menus,
    login: state => state.login,
    user: state => state.user,
    help: state => state.help,
    flags: state => state.flags,

    permissions: state => state.user.permissions,
    token: state => state.user.token,
    flag: state => (key) => state.flags[key],
};


export const writePersistLocale = persistWriter('state.locale');
export const readPersistLocale = persistReader('state.locale');
export const writePersistUser = persistWriter('state.user');
export const readPersistUser = persistReader('state.user');
export const writePersistUserToken = persistWriter('state.token');
export const readPersistUserToken = persistReader('state.token');
export const writePersistLogin = persistWriter('state.login');
export const readPersistLogin = persistReader('state.login');


export const LOAD_LOCAL_LOCALE = 'LOAD_LOCAL_LOCALE';
export const LOAD_LOCAL_LOGIN = 'LOAD_LOCAL_LOGIN';
export const LOAD_LOCAL_USER = 'LOAD_LOCAL_USER';


const actions = {
    [LOAD_LOCAL_LOCALE](context, {vm}) {
        if (!vm) {
            console.error('[LOAD_LOCAL_LOCALE] {vm} argument is required');
            return;
        }
        let locale = readPersistLocale();
        if (!vm.$i18n) {
            console.error('[LOAD_LOCAL_LOCALE] vm.$i18n is not initialized before loading locale');
            return;
        }
        vm.$i18n.locale = empty(locale) ? getBrowserLanguage() : locale;
        context.commit(SET_LOCALE, vm.$i18n.locale);
    },
    [LOAD_LOCAL_LOGIN](context) {
        let login = readPersistLogin();
        context.commit(SET_LOGIN, empty(login) ? {} : login);
    },
    [LOAD_LOCAL_USER](context) {
        let user = readPersistUser();
        context.commit(SET_USER, empty(user) ? {permissions: [], token: ''} : user);
    },
};


const mutations = {
    //persist
    [SET_LOCALE](state, locale) {
        state.locale = locale;
        writePersistLocale(locale);
    },
    [SET_LOGIN](state, login) {
        state.login = login;
        writePersistLogin(state.login);
    },
    [SET_USER](state, user) {
        state.user = user;
        writePersistUser(state.user);
        if (user.token) {
            writePersistUserToken(user.token);
        }
    },
    //directly set
    [SET_SYSTEM](state, system) {
        state.system = system;
    },
    [SET_MENUS](state, menus) {
        state.menus = menus;
    },
    [SET_HELP](state, help = '') {
        state.help = help;
    },
    [SET_FLAGS](state, flags = {}) {
        state.flags = flags;
    },
    //directly set sub state
    [SET_PERMISSIONS](state, permissions) {
        state.user.permissions = permissions;
    },
    [SET_TOKEN](state, token) {
        state.user.token = token;
    },
    [SET_ONE_FLAG](state, {flag, value}) {
        state.flags[flag] = value;
    },
};


export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
};
