import {SET_HELP} from "../store";
import {permit}   from "../utils/assist";

const help = {
    bind: function (el, binding, vnode) {
        const modifiers = binding.modifiers;
        const sHelpText = binding.value;

        let root = vnode.context.$root;
        if (!root.$store) {
            console.error('$store not injected before using help directive');
            return;
        }

        el.addEventListener('mouseenter', function() {
            root.$store.commit(SET_HELP, sHelpText);
        });
        el.addEventListener('mouseleave', function() {
            root.$store.commit(SET_HELP, null);
        });
    },

    unbind: function (el, binding, vnode) {
        // see https://github.com/vuejs/vue/issues/314
        // no need to remove
        // el.removeEventListener('mouseenter');
        // el.removeEventListener('mouseleave');
    }
};


const can = {
    inserted: function (el, binding, vnode) {
        let root = vnode.context.$root;
        if (!root.$store) {
            console.error('$store not injected before using can directive');
            return;
        }
        let arrUserProvidedPermissions = root.$store.getters['permissions'];

        let arrNeedAnyPermissions = [];
        if (root.empty(binding.value)){
            return;
        } else if (root.isArray(binding.value)){
            arrNeedAnyPermissions = binding.value;
        } else {
            arrNeedAnyPermissions.push(binding.value);
        }

        let bPermitAccess = permit(arrNeedAnyPermissions, arrUserProvidedPermissions);
        if (bPermitAccess === false) {
            vnode.elm.parentElement.removeChild(vnode.elm);
        }
    },
};


export default {
    help,
    can
}
