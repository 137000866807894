<template>
    <div class="password-strength-index">
        <Row :gutter="6" class="meter">
            <Col span="6">
                <div :class="{one: true, red: strength > 0}"></div>
            </Col>
            <Col span="6">
                <div :class="{one: true, orange: strength >= 50}"></div>
            </Col>
            <Col span="6">
                <div :class="{one: true, yellow: strength >= 70}"></div>
            </Col>
            <Col span="6">
                <div :class="{one: true, green: strength >= 90}"></div>
            </Col>
        </Row>
        <div class="description">
            <span v-if="strength >= 90">{{ $t('Strong') }}</span>
            <span v-else-if="strength >= 70">{{ $t('Good') }}</span>
            <span v-else-if="strength >= 50">{{ $t('Weak') }}</span>
            <span v-else>{{ $t('Very Weak') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "PasswordStrength",
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    methods: {
        //SEE https://stackoverflow.com/questions/948172/password-strength-meter
        scorePassword(pass) {
            let score = 0;
            if (!pass) {
                return score;
            }

            // award every unique letter until 5 repetitions
            let letters = {};
            for (let i = 0; i < pass.length; i++) {
                letters[pass[i]] = (letters[pass[i]] || 0) + 1;
                score += 5.0 / letters[pass[i]];
            }

            // bonus points for mixing it up
            let variations = {
                digits: /\d/.test(pass),
                lower: /[a-z]/.test(pass),
                upper: /[A-Z]/.test(pass),
                nonWords: /\W/.test(pass),
            }

            let variationCount = 0;
            for (let check in variations) {
                variationCount += (variations[check] == true) ? 1 : 0;
            }
            score += (variationCount - 1) * 10;

            return parseInt(score);
        }
    },
    computed: {
        strength() {
            return this.scorePassword(this.value);
        },
    },
}
</script>
