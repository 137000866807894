import Vue                         from "vue";
import {formatBytes, RMBUppercase} from "../utils/assist";
import DatetimeUtils               from "../utils/date";

export const sex = (value) => {
    switch (value) {
        case 0:
            return Vue.i18n.t('Female');
        case 1:
            return Vue.i18n.t('Male');
        default:
            return Vue.i18n.t('Unknown');
    }
};


export const capitalize = (value) => {
    if (!value) {
        return '';
    }
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1)
};


export const bytes = (value) => {
    if (value === undefined || value === null){
        return '--';
    }

    return formatBytes(value);
};


export const truncate = (value, start, end) => {
    return value.substring(start, end)
};


// EG: 2018-12-12 10:10:10 | date('yyyy-MM-dd HH:mm')
export const date = (value, format) => {
    return DatetimeUtils.format(value, format)
};


export const rmb = (cny) => {
    if (!cny) {
        return '';
    }
    return RMBUppercase(cny);
};


export const func = (value, callback, ...data) => {
    if (typeof callback === 'function') {
        return callback(value, ...data);
    }
    return value;
};


export default {
    sex,
    capitalize,
    bytes,
    truncate,
    date,
    rmb,
    func,
}
