<template>
    <div class="sub-menus">
        <div class="title">
            <Icon class="bg" :custom="sIcon" size="100" color="rgba(204, 204, 204, 0.7)"/>
            <div class="text">
                {{ sTitle }}
            </div>
        </div>
        <div class="menu-area">
<!--            <div v-for="oSubMenu in arrSubMenus" :key="oSubMenu.to" v-can="oSubMenu.permissions" v-if="!oSubMenu.disabled" class="each-link not-selectable">-->
<!--                <router-link tag="div" :to="oSubMenu.to" class="link">{{ oSubMenu.name }}</router-link>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
export default {
    name: "ThirdMenus",
    props: {
        sTitle: String,
        sIcon: String,
        arrSubMenus: Array,
    }
}
</script>

